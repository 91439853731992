<template>
    <v-autocomplete
      :search-input.sync="search"
      v-model="selected"
      :items="items"
      :label="label"
      :multiple="multiple"
      :clearable="clearable"
      :open-on-clear="$props.openOnClear"
      :small-chips="$props.smallChips"
      :no-data-text="$props.noDataText"
      :deletable-chips="$props.deletableChips"
      :item-text="$props.itemText"
      :item-value="$props.itemValue"
      :return-object="$props.returnObject"
      :select-all="$props.selectAll"
      @change="change"
      :allow-overflow="$props.allowOverflow"
      :menu-props="{ offsetOverflow: true }"
      dense
    >
      <template v-slot:prepend-item>
        <v-list-item dense>
          <v-list-item-action v-if="multiple" @click="toggleAll">
            <v-icon v-if="allSelected"> mdi-checkbox-multiple-marked </v-icon>
            <v-icon v-else> mdi-checkbox-multiple-marked-outline </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <v-text-field dense v-model="search"></v-text-field>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>
      <template v-if="value && maxItems" v-slot:selection="{ item, index }">
        <span v-if="index < maxItems">
          <span>{{ item.text }}</span>
        </span>
        <span v-if="index === maxItems" class="grey--text text-caption">
          &nbsp;(+{{ value.length - maxItems - 1 }} other{{
            value.length - maxItems > 1 ? "s" : ""
          }})
        </span>
      </template>
      <template v-else v-slot:selection="{ item }">
        <v-chip label x-small>
          {{ item[$props.itemText] || item }}
        </v-chip>
      </template>
    </v-autocomplete>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      selected: null,
    };
  },
  props: {
    value: [String, Array, Object],
    items: Array,
    label: String,
    multiple: Boolean,
    clearable: Boolean,
    "open-on-clear": Boolean,
    "small-chips": Boolean,
    "no-data-text": String,
    "item-text": String,
    "item-value": String,
    "deletable-chips": Boolean,
    maxItems: Number,
    "return-object": Boolean,
    "select-all": Boolean,
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.selected = val
      },
      deep:true
    },
  },
  computed: {
    filteredItems() {
      return this.search
        ? this.items.filter((item) =>
            JSON.stringify(item).toLowerCase().match(this.search.toLowerCase())
          )
        : this.items;
    },
    allSelected() {
      return (
        this.multiple &&
        this.selected &&
        this.selected.length === this.filteredItems.length
      );
    },
  },
  methods: {
    toggleAll() {
      if (this.allSelected) {
        this.search = "";
        this.selected = [];
      } else {
        this.selected = this.filteredItems;
      }
      this.$emit("input", this.selected);
    },
    change(evt) {
      this.$emit("input", evt);
    },
  },
};
</script>

<style>
</style>