<template>
  <v-text-field
  class="NumberFilter"
    type="number"
    hide-details
    solo
    dense
    single-line
          :placeholder="label"

          prepend-icon="mdi-counter"

  ></v-text-field>
</template>

<script>
export default {
    props:{
        options:{
            type: Object,
            default: ()=>({})
        },
        label:{
                    type: String,
            default: ()=>'Enter Text'
        }
    }
};
</script>

<style >
/* Chrome, Safari, Edge, Opera */
 .NumberFilter input::-webkit-outer-spin-button,
  .NumberFilter input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
  .NumberFilter  input[type=number] {
  -moz-appearance: textfield;
}

</style>