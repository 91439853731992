<template>
  <v-text-field
   class="TextFilter"
  dense
      solo
    single-line
      hide-details
      :placeholder="label"
      prepend-icon="mdi-form-textbox"

  ></v-text-field>
</template>

<script>
export default {
    props:{
        options:{
            type: Object,
            default: ()=>({})
        },
        label:{
                    type: String,
            default: ()=>'Enter Text'
        }
    }

}
</script>

