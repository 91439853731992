<template>
  <div class="flex-grow-1 d-flex" style="max-height: calc(100vh - 80px)">
    <v-card class="flex-grow-0 mr-2" v-if="open || !$route.params.dashboard">
      <v-card-text>
        <v-text-field autofocus v-model="dashboardSearch" dense></v-text-field>
        <v-list
          dense
          style="max-height: calc(100vh - 200px)"
          class="overflow-y-auto"
        >
          <template v-for="(dashboards, category) in filteredDashboards">
            <v-subheader :style="{ height: 'auto' }" :key="category">{{
              category
            }}</v-subheader>
            <v-list-item
              :style="{ height: 'auto' }"
              v-for="dashboard in dashboards"
              :key="dashboard.id"
              class="ml-2"
              @click="openDashboard(dashboard)"
            >
              {{ dashboard.name }}
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>

    <v-card class="flex-grow-1 flex-column"> 
      <v-card-text class="d-flex flex-grow-1 flex-column" :style="{height:'100%'}">
        <v-app-bar dense dark class="flex-shrink-1 flex-grow-0">
          <v-icon @click="open = true">mdi-menu</v-icon>
          <template v-if="dashboard">
            <v-toolbar-title class="flex-grow-1 ml-3">{{ dashboard.name }}</v-toolbar-title>
            <v-spacer></v-spacer>
                        <v-btn icon  dark class="ml-3" @click="editting=true"
                  v-if="dashboard && !editting"
            >
            <v-icon>mdi-note-edit</v-icon>
            </v-btn>
          </template>
        </v-app-bar>
        <template >
        <v-form dense class=" pa-2 " v-show="dashboard && editting">
                      <template v-if="dashboard">
              <component
                v-for="filter in dashboard.filters"
                :key="filter.key"
                v-bind:is="filter.component"
                :options="filter.options"
                :label="filter.label"
                v-model="testParams[filter.key]"
                class="flex-grow-0 ml-2"
              ></component>
            </template>
            <v-btn color="primary" class="ml-3" @click="getMetabaseUrl"
                    :disabled="loading"
                    :loading="loading"
                  v-if="dashboard"
            >Load</v-btn>
        </v-form>
        </template>
        <div class="d-flex flex-column flex-grow-1" >
                <iframe v-show="!loading && metabaseURL" class="flex-grow-1"  :src="metabaseURL"
                frameborder=0 @load="loadedIframe"
                 />
        </div>

        <!-- <div v-for="filter in dashboard.filters" :key="filter.key">
          <hr>
            <component v-bind:is="filter.component" :options="filter.options" :label="filter.label" v-model="testParams[filter.key]"></component>

          {{ filter }}
          <hr>
        </div> -->



        <!-- OOOOOOOOOOOOOLLLLLLLLLLLD -->
        <!-- Dashboards params
        <pre>{{ params }}</pre>
        valid
        <pre>{{ valid }}</pre>
        rawParams
        <pre>{{ rawParams }}</pre>

        <v-btn @click="getGroups">getGroups</v-btn>
        <v-btn @click="testToken">Test Token</v-btn>
        {{ date_filter }}
        validTest {{ validTest }}
        <v-form ref="form" v-model="validTest" class="d-flex flex-wrap pa-5">
          <template v-for="field in sampleDashboard.fields">
            <div
              v-if="field.newLine"
              :key="'nl' + field.id"
              style="flex-basis: 100%"
            />
            <component
              :key="field.id"
              :is="field.component"
              :options="field.options"
              v-model="rawParams[field.id]"
              @change="updateParams"
              @valid="checkValid"
              :style="{ width: field.fullWidth ? '100%' : 'auto' }"
            />
          </template>
        </v-form>
        <v-btn @click="reset">reset</v-btn>
        <v-btn @click="resetValidation">resetValidation</v-btn>
        <v-btn @click="validate">validate</v-btn>
        {{ rawParams }}
        {{ params }}
        <FieldDate v-model="date_filter" />
        <FancySelect
          :items="reports"
          v-model="selectedReport"
          item-text="name"
          return-object
          multiple
        />
        <v-btn @click="getMetabaseUrl">test</v-btn>
        <button>TEST</button> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DateFilter from "../components/filters/DateFilter.vue";
import TextFilter from "../components/filters/TextFilter.vue";
import NumberFilter from "../components/filters/NumberFilter.vue";
import sampleDB from "../sampleDB";
import FancySelect from "../components/fields/FancySelect.vue";
import FieldDate from "../components/fields/FieldDate.vue";
import FieldBrightPatternServiceSelect from "../components/fields/FieldBrightPatternServiceSelect.vue";

import firebase from "../firebase";
import { mapState } from "vuex";
const functions = firebase.functions();

export default {
  components: {
    FancySelect,
    FieldDate,
    FieldBrightPatternServiceSelect,
    DateFilter,
    TextFilter,
    NumberFilter,
  },
  data() {
    return {
      open: false,
      editting: true,
      dashboardSearch: "",
      tab: null,
      valid: {},
      validTest: null,
      rawParams: {},
      testParams: {},
      loading: false,
      sampleDashboard: {
        id: 1324,
        type: "dashboard",
        fields: [
          {
            id: "1",
            component: "FieldDate",
            options: { optional: true, key: "date_filter" },
            defaultValue: "",
            key: "date_filter",
          },
          {
            id: "1a",
            component: "FieldDate",
            options: { annual: true, key: "date_filtera" },
            defaultValue: "",
            key: "date_filter",
          },

          {
            id: "2",
            component: "FieldDate",
            options: { month: true, key: "date_filter2" },
            defaultValue: "",
            key: "date_filter2",
          },

          {
            id: "3",

            component: "FieldDate",
            options: { range: true, key: ["date_filter3", "date_filter4"] },
            defaultValue: [],
            key: ["date_filter3", "date_filter4"],
          },
          {
            id: "4",
            component: "FieldDate",
            options: {
              month: true,
              range: true,
              key: ["date_filter5", "date_filter6"],
            },
            defaultValue: [],
            key: ["date_filter5", "date_filter6"],
          },

          {
            id: "5",
            component: "FieldBrightPatternServiceSelect",
            options: { key: "service_filter", preSelectAll: true },
            defaultValue: [],
            newLine: true,
            fullWidth: true,
            key: "service_filter",
          },
        ],
      },
      date_filter: "",
      selectedReport: [],
      params: {},
      reports: sampleDB.clients[0].brightPattern.services,
      metabaseURL: null,
    };
  },
  computed: {
    ...mapState(["dashboards"]),
    filteredDashboards() {
      if (!this.dashboardSearch) {
        let result = this.dashboards.reduce((obj, dashboard) => {
          obj[dashboard.category] = obj[dashboard.category] || [];
          obj[dashboard.category].push(dashboard);

          return obj;
        }, {});
        return result;
      } else {
        let result = this.dashboards.reduce((obj, dashboard) => {
          let keywords =
            `${dashboard.name} ${dashboard.category} ${dashboard.description} `.toLowerCase();
          let reg = new RegExp(this.dashboardSearch.toLowerCase(), "igm");
          console.log({ keywords }, keywords.search(reg));
          if (keywords.search(reg) < 0) {
            return obj;
          }
          obj[dashboard.category] = obj[dashboard.category] || [];
          obj[dashboard.category].push(dashboard);

          return obj;
        }, {});
        return result;
      }
    },
    dashboard() {
      let dashboards = this.dashboards;
      let id = this.$route.params.dashboard;
      // let params = this.$route.params.params
      if (!id) {
        return null;
      }
      let dashboard =
        dashboards.filter((dashboard) => dashboard.id === id)[0] || null;
      // this.rawParams = params || {}
      return dashboard;
    },
  },
  watch: {
    "$route.params.params": {
      immediate: true,
      deep: true,
      handler(val) {
        this.rawParams = val || {};
      },
    },
    // rawParams: {
    //   immediate: true,
    //   deep: true,
    //   handler(val) {
    //     console.log("RawPArams", JSON.stringify(val));
    //     if (!val || JSON.stringify(val) === "{}") {
    //       return;
    //     }
    //     Object.keys(val).forEach((fieldId) => {
    //       console.log("INIT", { val, fieldId });
    //       if (val[fieldId] === null) {
    //         return;
    //       }
    //       let keys = Object.keys(val[fieldId]);
    //       console.log("KEYS", keys);
    //       // if (!keys.length){
    //       //   console.log('DELKEYS', keys)
    //       //   val[fieldId] = null
    //       // }
    //       let foundValue;
    //       keys.forEach((item) => {
    //         console.log({ item });
    //         if (val[fieldId][item] === null) {
    //           delete val[fieldId][item];
    //           delete this.params[item];
    //         } else {
    //           foundValue = true;
    //         }
    //       });
    //       if (!foundValue) {
    //         delete val[fieldId];
    //         keys.forEach((item) => {
    //           delete this.params[item];
    //         });
    //       }
    //       console.log({ val, fieldId, valkey: val[fieldId] });
    //       this.params = { ...this.params, ...val[fieldId] };
    //     });
    //     if (JSON.stringify(val) === this.$route.params.params) {
    //       console.log("Same path");
    //       return;
    //     }
    //     console.log("CHANGE ROUTE", JSON.stringify(val), this.$route);
    //     let jsonParams = JSON.stringify(val);
    //     if (jsonParams === "{}") {
    //       console.log("ROUTE tot ROOT");
    //       jsonParams = null;
    //       // delete this.$route.params.params
    //       // this.$router.replace(this.$route);
    //       //return
    //     }
    //     console.log("REROUTE");
    //     this.$router
    //       .replace({
    //         name: "dashboards",
    //         params: {
    //           dashboard: "test",
    //           params: jsonParams,
    //         },
    //       })
    //       .catch((error) => {
    //         if (error.name != "NavigationDuplicated") {
    //           throw error;
    //         }
    //       });
    //   },
    // },
    // $route: {
    //   immediate: true,
    //   deep: true,
    //   handler(val) {
    //     console.log("((((((((((((((((((((((", val);
    //     if (val) {
    //       if (val.params.dashboard) {
    //         let dashboard = this.dashboards.filter((d) => {
    //           console.log({ d, dashboard });
    //           return d.id === val.params.dashboard;
    //         })[0];
    //         console.log({ dashboard });
    //         if (dashboard) {
    //           this.dashboard = dashboard;
    //         }
    //       }
    //       this.rawParams = JSON.parse(val.params.params || "{}");
    //     } else {
    //       this.rawParams = {};
    //       this.dashboard = null;
    //     }
    //   },
    // },

    // params: {
    //   handler(val) {
    //     console.log({ val });
    //     // if (JSON.stringify(val) === this.$route.params.params){
    //     //   console.log('Same path')
    //     //   return
    //     // }
    //     // this.$router.push({
    //     //   name: 'dashboards',
    //     //   params: {
    //     //     dashboard: 'test',
    //     //     params: JSON.stringify(val)
    //     //   }
    //     // })
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    this.$nextTick(() => {});
    // check if valid
  },
  methods: {
    loadedIframe(evt){
      console.log('EDITING NOT')
      console.log('------------------------------------------', evt)
              this.loading = false
              if(this.metabaseURL){
              this.editting = false

              }


    },
    openDashboard(dashboard) {
      this.open = false;
      // this.dashboard = dashboard;
      let to = this.$route;
      if (to.params.dashboard === dashboard.id) {
        return;
      }
      to.params.dashboard = dashboard.id;
      this.$router.push(to);
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    checkValid(val) {
      console.log("valid", val);
      this.valid = { ...this.valid, ...val };
    },
    updateParams(val) {
      console.log("UPDATE PARAMS", val);
      this.params = { ...this.params, ...val };
    },
    async testToken() {
      let test = functions.httpsCallable("setClaim");
      await test();
    },
    async getGroups() {
      console.log("GET GROUPS IN DASH");
      let getGroups = functions.httpsCallable("getUserData");
      let resp = await getGroups();
      console.log(resp);
    },
    getMetabaseUrl() {
      this.loading = true
      let getMetabaseUrl = functions.httpsCallable("generateMetabaseUrl");
      let payload = {
        type: this.dashboard.metabaseType,
        id: this.dashboard.metabaseId,
        params: this.testParams,
      }
      console.log({payload})
      getMetabaseUrl(payload).then((result) => {
        console.log(result)
        // Read result of the Cloud Function.
        if (result.data && result.data.metabaseURL) {
          this.metabaseURL = result.data.metabaseURL;
          if (this.dashboard.autoRefresh) {
            this.metabaseURL = `${this.metabaseURL}&refresh=${this.dashboard.autoRefresh}`
          }
        }
        console.log({ result });
      });
    },
  },
};
</script>

<style>
</style>